<template>
  <div>
    <notifications position="top center" />
    <notifications group="trx" position="bottom left" />

    <router-view></router-view>
  </div>
</template>

<script>
import pusher from "./apis/pusher";
import axios from "./apis/api";

export default {
  name: "App",
  created() {
    this.$store.commit("FIRST_INITIATION");
  },

  methods: {
    async getBalance() {
      const response = await axios.get("/balance");
      this.$store.commit("set", ["balance", response.data.data.balance]);
    },

    async loadItemsTrx() {
      let response = await axios.get("/transaction", {
        params: this.$store.state.trx_list_param,
      });
      this.totalRecords = response.data.data.meta.total;
      this.$store.commit("set", ["trx_list", response.data.data.values]);
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  watch: {
    user(newVal, oldVal) {
      pusher.unsubscribe(oldVal.channel);
      let channel = pusher.subscribe(newVal.channel);
      channel.bind("update_balance", () => {
        this.getBalance();
      });
      channel.bind("notif_trx", (data) => {
        let type = "error";
        switch (data.status) {
          case "failed":
            type = "error";
            break;
          case "success":
            type = "success";
            break;
          case "pending":
            type = "warn";
            break;

          default:
            type = "error";
            break;
        }
        this.$notify({
          group: "trx",
          duration: 7000,
          type: type,
          title: data.message,
          text:
            "Customer No: " +
            data.customer_no +
            "<br />" +
            "Serial: " +
            data.serial,
        });
        this.$store.commit("set", ["last_trx", data]);
      });
      channel.bind("update_trx", () => {
        if (this.$route.name == "TransactionList") {
          this.loadItemsTrx();
        }
      });
    },
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
