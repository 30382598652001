import axios from "axios";
import store from "../store";

const axiosInstance = axios.create({
    baseURL: process.env.VUE_APP_API,
});

axiosInstance.interceptors.request.use(async function (config) {
    config.headers["Authorization"] = "Bearer " + store.state.access_token;
    return config;
});

axiosInstance.interceptors.response.use(
    async function (response) {
        return response;
    },
    async function (error) {
        if (error.response.status == 401) {
            store.commit("INITIATE_LOGOUT");
        }
        return Promise.reject(error);
    }
);

export default axiosInstance;