import Vue from 'vue'
import Vuex from 'vuex'
import router from './router'
Vue.use(Vuex)

const state = {
  sidebarShow: 'responsive',
  sidebarMinimize: false,
  access_token: null,
  login_credential: {},

  balance: 0,
  user: {},

  tupupSaldo: {},

  trx_list: [],
  trx_list_param: {
    columnFilters: {},
    sort: {
      field: "",
      type: "",
    },
    page: 1,
    perPage: 10,
  },
  deposit_list: [],
  deposit_list_param: {
    columnFilters: {},
    sort: {
      field: "",
      type: "",
    },
    page: 1,
    perPage: 10,
  },

  last_trx: {},

}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  INITIATE_LOGOUT(state) {
    state.access_token = null
    state.login_credential = {}

    localStorage.removeItem('access_token');
    localStorage.removeItem('login_credential');

    router.push('/auth');
  },
  INITIATE_LOGIN(state, access_token) {
    state.access_token = access_token

    localStorage.setItem('access_token', access_token);
  },
  LOGIN_CREDENTIAL(state, login_credential) {
    state.login_credential = login_credential

    localStorage.setItem('login_credential', JSON.stringify(login_credential))
  },
  FIRST_INITIATION(state) {
    state.access_token = localStorage.getItem('access_token');

    state.login_credential = JSON.parse(localStorage.getItem('login_credential'));
  },


}

export default new Vuex.Store({
  state,
  mutations
})